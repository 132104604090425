// Don't change this file manually, instead run: npm run icons:update to automatically generate this file from all svg files in the assets/icons folder.

import arrowDownLong from "assets/icons/arrow-down-long.svg";
import arrowRightV2 from "assets/icons/arrow-right-v2.svg";
import arrowRight from "assets/icons/arrow-right.svg";
import bestseller from "assets/icons/badges/bestseller.svg";
import fastResponse from "assets/icons/badges/fast_response.svg";
import highDemand from "assets/icons/badges/high_demand.svg";
import topVendor from "assets/icons/badges/top_vendor.svg";
import bullet from "assets/icons/bullet.svg";
import burger from "assets/icons/burger.svg";
import caretDown from "assets/icons/caret-down.svg";
import cart from "assets/icons/cart.svg";
import checkmarkStamp from "assets/icons/checkmark-stamp.svg";
import checkmark from "assets/icons/checkmark.svg";
import checkmarkCircle from "assets/icons/checkmark_circle.svg";
import clock from "assets/icons/clock.svg";
import close from "assets/icons/close.svg";
import cross from "assets/icons/cross.svg";
import customerAppCalendarFrame from "assets/icons/customer-app/customer-app-calendar-frame.svg";
import customerAppChatBubble from "assets/icons/customer-app/customer-app-chat-bubble.svg";
import customerAppClipboard from "assets/icons/customer-app/customer-app-clipboard.svg";
import customerAppClose from "assets/icons/customer-app/customer-app-close.svg";
import customerAppGear from "assets/icons/customer-app/customer-app-gear.svg";
import customerAppHouse from "assets/icons/customer-app/customer-app-house.svg";
import customerAppMarketplaceShop from "assets/icons/customer-app/customer-app-marketplace-shop.svg";
import customerAppNavigationMenu from "assets/icons/customer-app/customer-app-navigation-menu.svg";
import customerAppOfficehub from "assets/icons/customer-app/customer-app-officehub.svg";
import customerAppPersonsGroup from "assets/icons/customer-app/customer-app-persons_group.svg";
import customerAppReceipt from "assets/icons/customer-app/customer-app-receipt.svg";
import divider from "assets/icons/divider.svg";
import edit from "assets/icons/edit.svg";
import email from "assets/icons/email.svg";
import facebook from "assets/icons/facebook.svg";
import file from "assets/icons/file.svg";
import globe from "assets/icons/globe.svg";
import handTime from "assets/icons/hand-time.svg";
import heartBubbly from "assets/icons/heart-bubbly.svg";
import infoCircle from "assets/icons/info-circle.svg";
import infoFilled from "assets/icons/info-filled.svg";
import label from "assets/icons/label.svg";
import linkExternal from "assets/icons/link-external.svg";
import linkedin from "assets/icons/linkedin.svg";
import loading from "assets/icons/loading.svg";
import location from "assets/icons/location.svg";
import marketplace from "assets/icons/marketplace.svg";
import minus from "assets/icons/minus.svg";
import pcSupport from "assets/icons/pc-support.svg";
import phone from "assets/icons/phone.svg";
import picture from "assets/icons/picture.svg";
import plus from "assets/icons/plus.svg";
import protection from "assets/icons/protection.svg";
import quoteEnd from "assets/icons/quote-end.svg";
import quoteStart from "assets/icons/quote-start.svg";
import reply from "assets/icons/reply.svg";
import search from "assets/icons/search.svg";
import service1 from "assets/icons/services/service_1.svg";
import service10 from "assets/icons/services/service_10.svg";
import service11 from "assets/icons/services/service_11.svg";
import service12 from "assets/icons/services/service_12.svg";
import service13 from "assets/icons/services/service_13.svg";
import service14 from "assets/icons/services/service_14.svg";
import service15 from "assets/icons/services/service_15.svg";
import service16 from "assets/icons/services/service_16.svg";
import service17 from "assets/icons/services/service_17.svg";
import service18 from "assets/icons/services/service_18.svg";
import service19 from "assets/icons/services/service_19.svg";
import service2 from "assets/icons/services/service_2.svg";
import service20 from "assets/icons/services/service_20.svg";
import service21 from "assets/icons/services/service_21.svg";
import service22 from "assets/icons/services/service_22.svg";
import service23 from "assets/icons/services/service_23.svg";
import service24 from "assets/icons/services/service_24.svg";
import service25 from "assets/icons/services/service_25.svg";
import service26 from "assets/icons/services/service_26.svg";
import service27 from "assets/icons/services/service_27.svg";
import service28 from "assets/icons/services/service_28.svg";
import service29 from "assets/icons/services/service_29.svg";
import service3 from "assets/icons/services/service_3.svg";
import service30 from "assets/icons/services/service_30.svg";
import service31 from "assets/icons/services/service_31.svg";
import service32 from "assets/icons/services/service_32.svg";
import service33 from "assets/icons/services/service_33.svg";
import service34 from "assets/icons/services/service_34.svg";
import service35 from "assets/icons/services/service_35.svg";
import service36 from "assets/icons/services/service_36.svg";
import service37 from "assets/icons/services/service_37.svg";
import service38 from "assets/icons/services/service_38.svg";
import service39 from "assets/icons/services/service_39.svg";
import service4 from "assets/icons/services/service_4.svg";
import service40 from "assets/icons/services/service_40.svg";
import service41 from "assets/icons/services/service_41.svg";
import service42 from "assets/icons/services/service_42.svg";
import service43 from "assets/icons/services/service_43.svg";
import service44 from "assets/icons/services/service_44.svg";
import service45 from "assets/icons/services/service_45.svg";
import service46 from "assets/icons/services/service_46.svg";
import service47 from "assets/icons/services/service_47.svg";
import service48 from "assets/icons/services/service_48.svg";
import service49 from "assets/icons/services/service_49.svg";
import service5 from "assets/icons/services/service_5.svg";
import service50 from "assets/icons/services/service_50.svg";
import service51 from "assets/icons/services/service_51.svg";
import service52 from "assets/icons/services/service_52.svg";
import service53 from "assets/icons/services/service_53.svg";
import service54 from "assets/icons/services/service_54.svg";
import service55 from "assets/icons/services/service_55.svg";
import service56 from "assets/icons/services/service_56.svg";
import service57 from "assets/icons/services/service_57.svg";
import service58 from "assets/icons/services/service_58.svg";
import service59 from "assets/icons/services/service_59.svg";
import service6 from "assets/icons/services/service_6.svg";
import service60 from "assets/icons/services/service_60.svg";
import service61 from "assets/icons/services/service_61.svg";
import service62 from "assets/icons/services/service_62.svg";
import service63 from "assets/icons/services/service_63.svg";
import service64 from "assets/icons/services/service_64.svg";
import service65 from "assets/icons/services/service_65.svg";
import service66 from "assets/icons/services/service_66.svg";
import service67 from "assets/icons/services/service_67.svg";
import service68 from "assets/icons/services/service_68.svg";
import service69 from "assets/icons/services/service_69.svg";
import service7 from "assets/icons/services/service_7.svg";
import service70 from "assets/icons/services/service_70.svg";
import service71 from "assets/icons/services/service_71.svg";
import service72 from "assets/icons/services/service_72.svg";
import service73 from "assets/icons/services/service_73.svg";
import service74 from "assets/icons/services/service_74.svg";
import service75 from "assets/icons/services/service_75.svg";
import service76 from "assets/icons/services/service_76.svg";
import service77 from "assets/icons/services/service_77.svg";
import service78 from "assets/icons/services/service_78.svg";
import service8 from "assets/icons/services/service_8.svg";
import service9 from "assets/icons/services/service_9.svg";
import shoppingBasket from "assets/icons/shopping-basket.svg";
import shoppingCartNo from "assets/icons/shopping_cart_no.svg";
import starCircle from "assets/icons/star-circle.svg";
import starEmpty from "assets/icons/star-empty.svg";
import starFull from "assets/icons/star-full.svg";
import starHalf from "assets/icons/star-half.svg";
import thumbsUp from "assets/icons/thumbs-up.svg";
import trash from "assets/icons/trash.svg";
import trophy from "assets/icons/trophy.svg";
import upload from "assets/icons/upload.svg";
import user from "assets/icons/user.svg";
import verifiedCheckmark from "assets/icons/verified-checkmark.svg";
import warning from "assets/icons/warning.svg";

export default {
  "arrow-down-long": arrowDownLong,
  "arrow-right-v2": arrowRightV2,
  "arrow-right": arrowRight,
  bestseller,
  fast_response: fastResponse,
  high_demand: highDemand,
  top_vendor: topVendor,
  bullet,
  burger,
  "caret-down": caretDown,
  cart,
  "checkmark-stamp": checkmarkStamp,
  checkmark,
  checkmark_circle: checkmarkCircle,
  clock,
  close,
  cross,
  "customer-app-calendar-frame": customerAppCalendarFrame,
  "customer-app-chat-bubble": customerAppChatBubble,
  "customer-app-clipboard": customerAppClipboard,
  "customer-app-close": customerAppClose,
  "customer-app-gear": customerAppGear,
  "customer-app-house": customerAppHouse,
  "customer-app-marketplace-shop": customerAppMarketplaceShop,
  "customer-app-navigation-menu": customerAppNavigationMenu,
  "customer-app-officehub": customerAppOfficehub,
  "customer-app-persons_group": customerAppPersonsGroup,
  "customer-app-receipt": customerAppReceipt,
  divider,
  edit,
  email,
  facebook,
  file,
  globe,
  "hand-time": handTime,
  "heart-bubbly": heartBubbly,
  "info-circle": infoCircle,
  "info-filled": infoFilled,
  label,
  "link-external": linkExternal,
  linkedin,
  loading,
  location,
  marketplace,
  minus,
  "pc-support": pcSupport,
  phone,
  picture,
  plus,
  protection,
  "quote-end": quoteEnd,
  "quote-start": quoteStart,
  reply,
  search,
  service_1: service1,
  service_10: service10,
  service_11: service11,
  service_12: service12,
  service_13: service13,
  service_14: service14,
  service_15: service15,
  service_16: service16,
  service_17: service17,
  service_18: service18,
  service_19: service19,
  service_2: service2,
  service_20: service20,
  service_21: service21,
  service_22: service22,
  service_23: service23,
  service_24: service24,
  service_25: service25,
  service_26: service26,
  service_27: service27,
  service_28: service28,
  service_29: service29,
  service_3: service3,
  service_30: service30,
  service_31: service31,
  service_32: service32,
  service_33: service33,
  service_34: service34,
  service_35: service35,
  service_36: service36,
  service_37: service37,
  service_38: service38,
  service_39: service39,
  service_4: service4,
  service_40: service40,
  service_41: service41,
  service_42: service42,
  service_43: service43,
  service_44: service44,
  service_45: service45,
  service_46: service46,
  service_47: service47,
  service_48: service48,
  service_49: service49,
  service_5: service5,
  service_50: service50,
  service_51: service51,
  service_52: service52,
  service_53: service53,
  service_54: service54,
  service_55: service55,
  service_56: service56,
  service_57: service57,
  service_58: service58,
  service_59: service59,
  service_6: service6,
  service_60: service60,
  service_61: service61,
  service_62: service62,
  service_63: service63,
  service_64: service64,
  service_65: service65,
  service_66: service66,
  service_67: service67,
  service_68: service68,
  service_69: service69,
  service_7: service7,
  service_70: service70,
  service_71: service71,
  service_72: service72,
  service_73: service73,
  service_74: service74,
  service_75: service75,
  service_76: service76,
  service_77: service77,
  service_78: service78,
  service_8: service8,
  service_9: service9,
  "shopping-basket": shoppingBasket,
  shopping_cart_no: shoppingCartNo,
  "star-circle": starCircle,
  "star-empty": starEmpty,
  "star-full": starFull,
  "star-half": starHalf,
  "thumbs-up": thumbsUp,
  trash,
  trophy,
  upload,
  user,
  "verified-checkmark": verifiedCheckmark,
  warning,
};
